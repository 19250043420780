import { Head, Link, router } from "@inertiajs/react";
import {
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    Input,
    Row,
    Space,
    Typography,
    Grid,
    message,
} from "antd";
import logo from "@/assets/logo.svg";
import bgLogin from "@/assets/login-bg.svg";
export default function Login({ status, canResetPassword }) {
    const { useBreakpoint } = Grid;
    const { lg } = useBreakpoint();
    let [loginForm] = Form.useForm();

    // useEffect(() => {
    //     return () => {
    //         router.reset('password')
    //     }
    // }, [])
    const handleChangeRemember = (e) => {};
    const onSubmitLoginHandle = (values) => {
        const loginData = {
            email: values.email,
            password: values.password,
            remember: false,
        };
        router.post(route("login"), loginData, {
            onSuccess: (res) => {
                message.success("Login successful");
            },
            onError: (err) => {
                console.log(err)
                Object.keys(err).forEach(function (k) {
                    let value = err[k];
                    message.error(value);
                });
            },
        });
    };

    return (
        <>
            <Row>
                <Head title="Login" />
                <Col xs={0} md={12} lg={16}>
                    <div
                        style={{
                            background: "#f2f4fe",
                            minHeight: "100vh",
                            padding: "30px",
                        }}
                    >
                        <Space
                            className="w-100 justify-center items-center"
                            style={{ minHeight: "80vh" }}
                        >
                            <img width={lg ? 600 : 450} src={bgLogin} alt="" />
                        </Space>
                    </div>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Card
                        style={{
                            padding: "50px",
                            minHeight: "100vh",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Space direction="vertical" className="w-100">
                            <Space className="justify-center w-100">
                                <Typography.Title level={2} style={{fontWeight:'700'}}>
                                    Welcome to Empower Snapshot
                                </Typography.Title>
                            </Space>
                            <Form
                                layout="vertical"
                                onFinish={onSubmitLoginHandle}
                                form={loginForm}
                            >
                                <Form.Item
                                    name="email"
                                    label="Email Address"
                                    className="form__input"
                                    rules={[{ required: true, type: "email" }]}
                                >
                                    <Input size="large" />
                                </Form.Item>
                                <Form.Item
                                    label="Password"
                                    className="form__input"
                                    name={"password"}
                                    rules={[{ required: true }]}
                                >
                                    <Input.Password size="large" />
                                </Form.Item>
                                <Form.Item name={"remember"}>
                                    <Space
                                        style={{
                                            justifyContent: "space-between",
                                        }}
                                        className="w-100"
                                        wrap
                                    >
                                        <Space>
                                            <Checkbox
                                                onChange={handleChangeRemember}
                                            />
                                            <Typography.Text type="secondary">
                                                Remember Me
                                            </Typography.Text>
                                        </Space>
                                        {canResetPassword && (
                                            <Link
                                                href={route("password.request")}
                                            >
                                                Forgot your password?
                                            </Link>
                                        )}
                                    </Space>
                                </Form.Item>
                                <Button
                                    style={{ borderRadius: "4px" }}
                                    htmlType="submit"
                                    type="primary"
                                    block
                                    size="large"
                                >
                                    Login
                                </Button>
                            </Form>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
